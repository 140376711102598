import React, { useState } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import "./styles.css";
import SendIcon from '@mui/icons-material/Send';

import { useNavigate } from "react-router-dom";
import logo from "../../../src/logo.png";
function Index() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errortext, setErrortext] = useState("");

  let navigate = useNavigate();

  const login = () => {
    if (email == "" || password == "") {
      setErrortext("Please enter username and password");
      setError(true);
    } else if (email == "admin" && password == "admin") {
      let path = `/home`;
      navigate(path);
    } else {
      setErrortext("Invalid usename or password");
      setError(true);
    }
  };

  return (
    <>
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="card col-lg-7 cardclass">
            <div class="col  ">
              <div class="mb-3 row"></div>
              <div class="mb-3 row">
              

                <div class="row align-items-start">
                  <div class="col">
                     
                    <h2 class="mainhead">Welcome to BISTEC Dashboards</h2>
                  </div>
                   <div class="col">  <img src={logo} className="rounded mx-auto d-block imagelogo" alt="logo"/></div>
                </div>
              </div>

              {error ? (
                <Alert variant="filled" className="new" severity="error">
                  {errortext}
                </Alert>
              ) : (
                ""
              )}

              <div class="mb-3 row ">
                <label for="staticEmail" class="col-sm-2 col-form-label">
                  User Name
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="inputPassword" class="col-sm-2 col-form-label">
                  Password
                </label>
                <div class="col-sm-10">
                  <input
                    type="password"
                    class="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <div class="col-sm-2">
                  <Button variant="contained"  size="medium" endIcon={<SendIcon />} onClick={login}>
                    Login
                  </Button>
                </div>
                <div class="col-sm-10"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
