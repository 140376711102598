import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from "./containers/home";
import Login from "./containers/login";
import Dashboard from './containers/dashone';
import DashTw from './containers/dashtwo';
import DashThree from './containers/dashthree'

function App() {
  return (
    <BrowserRouter>
    <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='home' element={<Home/>}/>
            <Route path='dashone' element={<Dashboard/>}/>
            <Route path='dashone/:id' element={<Dashboard/>}/>
            <Route path='dashtwo' element={<DashTw/>}/>
            <Route path='dashthree' element={<DashThree/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
