import React, { useEffect, useState } from "react";
import Appbar from "../../components/appbar";
import axios from "axios";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function Index() {
 
  const [groupid, setGroupid] = useState(
    "bf8253ce-09e0-4098-a450-db5d7c4417ac"
  );
  const [reportID, setReportID] = useState(
    "c6821fc9-dc8c-4992-8492-f52997f0b8f5"
  );
  const [embbedurl, setEmbedurl] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [accessTokenTwo, setAccessTokenTwo] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // Define the data configuration
  const datafortoken = {
    datasets: [{ id: "29a33bd2-5994-4838-9fbf-6e1ecaf4d420" }],
    identities: "",
    reports: [{ id: reportID }],
    targetWorkspaces: "",
  };

  // Initialize the component
  useEffect(() => {
    gettingAccessToken();
  }, []);

  useEffect(() => {
    generateAccessToken();
  }, [accessToken]);

  useEffect(() => {
    getEmbedUrl();
  }, [accessTokenTwo]);

  // Function to get the embed URL
  const getEmbedUrl = () => {
    axios
      .get(
        `https://api.powerbi.com/v1.0/myorg/groups/${groupid}/reports/${reportID}`,
        config
      )
      .then(function (response) {
        setEmbedurl(response.data.embedUrl);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Function to get the access token
  const gettingAccessToken = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/WeatherForecast`)
      .then(function (response) {
        setAccessToken(response.data.access_token);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Function to generate the second access token
  const generateAccessToken = () => {
    axios
      .post(
        `${process.env.REACT_APP_POWERBI_GEN_TOKEN_URL}`,
        datafortoken,
        config
      )
      .then(function (response) {
        setAccessTokenTwo(response.data.token);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const navigate = useNavigate();

  const redirectToDashboard = () => {
    const path = "/home";
    navigate(path);
  };
  return (
    <>
      <div className="container">
        <Appbar />
        <div class="row justify-content-md-end">
          <div class="col col-lg-3">
            <Button
              variant="contained"
              size="large"
              onClick={redirectToDashboard}
              startIcon={<KeyboardBackspaceIcon />}
            >
              Back to Dashboards
            </Button>
          </div>
        </div>

        {embbedurl ? (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: reportID,
              embedUrl: embbedurl,
              accessToken: accessTokenTwo,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
              },
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "Sheet1",
                    column: "Tenant_Id",
                  },
                  operator: "In",
                  values: ["T2"],
                }
              ],
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    console.log("Report rendered");
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={"Embed-container"}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Index;
