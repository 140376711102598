import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import One from "../../../src/chartone.png";
import Two from "../../../src/charttwo.png";
import Three from "../../../src/chartthree.png";
import Dashboard from "../../components/dashboard/index";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import "./styles.css";
import Appbar from "../../components/appbar";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import NewOne from "../../../src/newone.png"
import NewTwo from "../../../src/newtwo.png"
import NewThree from "../../../src/changedone.png"
import newfour from "../../../src/changedtwo.png"
function Index() {
  let navigate = useNavigate();

  const dashone = () => {
    let path = `/dashone/1`;
    navigate(path);
  };

  const dashtwo = () => {
    let path = `/dashtwo`;
    navigate(path);
  };



//   const handleDragStart = (e) => e.preventDefault();

//   const items = [
//     <div class="col-3">
//       <Card sx={{ maxWidth: 345 }}>
//         <CardMedia
//           component="img"
//           alt="green iguana"
//           height="140"
//           image={One}
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="div">
//             Dashboard One
//           </Typography>
//           <Typography variant="body2" color="text.secondary"></Typography>
//         </CardContent>
//         <CardActions>
//           <Button
//             variant="contained"
//             size="small"
//             onClick={dashone}
//             endIcon={<ZoomInIcon />}
//           >
//             See
//           </Button>
//         </CardActions>
//       </Card>
//     </div>,
//     <div class="col-3">
//       <Card sx={{ maxWidth: 345 }}>
//         <CardMedia
//           component="img"
//           alt="green iguana"
//           height="140"
//           image={Two}
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="div">
//             Dashboard Two
//           </Typography>
//           <Typography variant="body2" color="text.secondary"></Typography>
//         </CardContent>
//         <CardActions>
//           <Button
//             variant="contained"
//             size="small"
//             onClick={dashtwo}
//             endIcon={<ZoomInIcon />}
//           >
//             See
//           </Button>
//         </CardActions>
//       </Card>
//     </div>,
//     <div class="col-3">
//       <Card sx={{ maxWidth: 345 }}>
//         <CardMedia
//           component="img"
//           alt="green iguana"
//           height="140"
//           image={Three}
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="div">
//             Dashboard Three
//           </Typography>
//           <Typography variant="body2" color="text.secondary"></Typography>
//         </CardContent>
//         <CardActions>
//           <Button
//             variant="contained"
//             size="small"
//             onClick={dashone}
//             endIcon={<ZoomInIcon />}
//           >
//             See
//           </Button>
//         </CardActions>
//       </Card>
//     </div>,
//     <div class="col-3">
//     <Card sx={{ maxWidth: 345 }}>
//       <CardMedia
//         component="img"
//         alt="green iguana"
//         height="140"
//         image={Three}
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//           Dashboard Three
//         </Typography>
//         <Typography variant="body2" color="text.secondary"></Typography>
//       </CardContent>
//       <CardActions>
//         <Button variant="contained"  size="small" onClick={dashone}  endIcon={<ZoomInIcon />}>
//           See
//         </Button>
//       </CardActions>
//     </Card>
//   </div>
//   ,
//   <div class="col-3">
//   <Card sx={{ maxWidth: 345 }}>
//     <CardMedia
//       component="img"
//       alt="green iguana"
//       height="140"
//       image={Three}
//     />
//     <CardContent>
//       <Typography gutterBottom variant="h5" component="div">
//         Dashboard Three
//       </Typography>
//       <Typography variant="body2" color="text.secondary"></Typography>
//     </CardContent>
//     <CardActions>
//       <Button variant="contained"  size="small" onClick={dashone}  endIcon={<ZoomInIcon />}>
//         See
//       </Button>
//     </CardActions>
//   </Card>
// </div>
// ,
// <div class="col-3">
// <Card sx={{ maxWidth: 345 }}>
//   <CardMedia
//     component="img"
//     alt="green iguana"
//     height="140"
//     image={Three}
//   />
//   <CardContent>
//     <Typography gutterBottom variant="h5" component="div">
//       Dashboard Three
//     </Typography>
//     <Typography variant="body2" color="text.secondary"></Typography>
//   </CardContent>
//   <CardActions>
//     <Button variant="contained"  size="small" onClick={dashone}  endIcon={<ZoomInIcon />}>
//       See
//     </Button>
//   </CardActions>
// </Card>
// </div>
// ,
// <div class="col-3">
// <Card sx={{ maxWidth: 345 }}>
//   <CardMedia
//     component="img"
//     alt="green iguana"
//     height="140"
//     image={Three}
//   />
//   <CardContent>
//     <Typography gutterBottom variant="h5" component="div">
//       Dashboard Three
//     </Typography>
//     <Typography variant="body2" color="text.secondary"></Typography>
//   </CardContent>
//   <CardActions>
//     <Button variant="contained"  size="small" onClick={dashone}  endIcon={<ZoomInIcon />}>
//       See
//     </Button>
//   </CardActions>
// </Card>
// </div>
//   ];

//   const responsive = {
//     0: { items: 1 },
//     568: { items: 2 },
//     1024: { items: 3 },
//   };
  return (
    <>
      <div class="container">
        <Appbar />
        {/* <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="alternate" 
          autoWidth={true}
        /> */}
        <div class="row align-items-center rowmid">
          <h3>
            {" "}
            <GridViewIcon />
            Strategic dashboards
          </h3>
          <div class="col-3">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={One}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                Tenant Id 1 Dashboard 
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={dashone}
                  endIcon={<ZoomInIcon />}
                >
                  See
                </Button>
              </CardActions>
            </Card>
          </div>
          <div class="col-3">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={Two}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                   Tenant Id 2 Dashboard 
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={dashtwo}
                  endIcon={<ZoomInIcon />}
                >
                  See
                </Button>
              </CardActions>
            </Card>
          </div>
          <div class="col-3">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={NewOne}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Dashboard Three
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={dashone}
                  endIcon={<ZoomInIcon />}
                >
                  See
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>

        <div class="row align-items-center rowmid">
          <h3>
            {" "}
            <GridViewIcon />
            Operational dashboards
          </h3>
          <div class="col-3">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={Three}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Dashboard One
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={dashone}
                  endIcon={<ZoomInIcon />}
                >
                  See
                </Button>
              </CardActions>
            </Card>
          </div>
          <div class="col-3">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={NewTwo}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Dashboard Two
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={dashone}
                  endIcon={<ZoomInIcon />}
                >
                  See
                </Button>
              </CardActions>
            </Card>
          </div>
          <div class="col-3">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={NewThree}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Dashboard Three
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={dashone}
                  endIcon={<ZoomInIcon />}
                >
                  See
                </Button>
              </CardActions>
            </Card>
          </div>
          <div class="col-3">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={newfour}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Dashboard Four
                </Typography>
                <Typography variant="body2" color="text.secondary"></Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={dashone}
                  endIcon={<ZoomInIcon />}
                >
                  See
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
