import * as React from "react";

import logo from "../../../src/logotwo.png";
import Button from "@mui/material/Button";
import LogoutIcon from '@mui/icons-material/Logout';
import "./styles.css";
import { useNavigate } from "react-router-dom";

function ResponsiveAppBar() {

  let navigate = useNavigate();

  const dashone = () => {
    let path = `/`;
    navigate(path);
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-2">
              <img src={logo} className="imagelogoo d-block" alt="logo" />
            </div>
            <div className="col-6">
              <h3> Welcome to BISTEC Dashboard</h3>
            </div>
            <div className="col-2">
             
            </div>
            <div className="col-1">
            <Button
              variant="contained"
              size="large"
              onClick={dashone}
              startIcon={<LogoutIcon />}
            >
              logout
            </Button>
            </div>
          </div>
        </div>
        {/* <div class="d-flex bd-highlight mb-3">
  <div class="p-2 bd-highlight">   <img src={logo} className="imagelogoo d-block" alt="logo" /></div>
  <div class="p-2 bd-highlight">  <h3> Welcome to BISTEC Dashboard</h3></div>
  <div class="ms-auto p-2 bd-highlight">Flex item</div>
</div> */}
      </div>
    </>
  );
}
export default ResponsiveAppBar;
