import React from 'react'
import { PowerBIEmbed } from 'powerbi-client-react';
import {models} from 'powerbi-client'
 function Dashboard(props) {

  return (
    <PowerBIEmbed
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: props.id,
            embedUrl:props.embedurl ,
            accessToken:  props.token,
            tokenType: models.TokenType.Aad,
        //    tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            }
          }}

          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }]
            ])
          }

          cssClassName={"Embed-container"}

          getEmbeddedComponent={(embeddedReport) => {
            window.report =embeddedReport;
            // this.report = embeddedReport as Report;
          }}
        />
  )
}

export default Dashboard