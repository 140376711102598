import React, { useEffect, useState ,useMemo ,useCallback } from "react";
import { useParams } from "react-router-dom";
import Appbar from "../../components/appbar";
import axios from "axios";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function Index() {
  let { id } = useParams();
  // const [groupid, setGroupid] = useState(
  //   "23da0174-bfb0-4f01-b061-df14af3e1232"
  // );
   
  const [groupid, setGroupid] = useState(
    "bf8253ce-09e0-4098-a450-db5d7c4417ac"
  );
  

  // const [reportID, setReportID] = useState(
  //   "950ed007-f679-4557-a182-5346b2f1888a"
  // );

  const [reportID, setReportID] = useState(
    "c6821fc9-dc8c-4992-8492-f52997f0b8f5"
  );

  const [embbedurl, setEmbedurl] = useState(null);
  const [accessToken, setAccessTooken] = useState(null);
  const [accessTokenTwo, setAccessTokenTwo] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const datafortoken = {
    datasets: [
      {
        id: "29a33bd2-5994-4838-9fbf-6e1ecaf4d420",
      },
    ],
    identities: "",
    reports: [
      {
        id: "c6821fc9-dc8c-4992-8492-f52997f0b8f5",
      },
    ],
    targetWorkspaces: "",
  };

  useEffect(() => {
    gettingaccesstoken();
  }, []);

  useEffect(() => {
    generateAccesstoken();
    // getembedurl();
  }, [accessToken]);

  // useEffect(() => {
  //   getembedurl();
  // }, [accessTokenTwo]);

  useEffect(() => {
    getembedurl();
  }, [accessTokenTwo]);

  // api to get the embed url

  const getembedurl = () => {
    console.log("function for getting emmbed url");
    axios
      .get(
        `https://api.powerbi.com/v1.0/myorg/groups/${groupid}/reports/${reportID}`,
        config
      )
      .then(function (response) {
        console.log("emmbed url loaded :" + response.data.embedUrl);
        setEmbedurl(response.data.embedUrl);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Api for get the token from backend

  const gettingaccesstoken = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/WeatherForecast`)
      .then(function (response) {
        console.log("Access token :" + response.data.access_token);
        console.log(response.data);

        setAccessTooken(response.data.access_token);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // api to generate a token

  const generateAccesstoken = () => {
    console.log("function for getting token two");
    axios
      .post(
        `${process.env.REACT_APP_POWERBI_GEN_TOKEN_URL}`,
        datafortoken,
        config
      )
      .then(function (response) {
        //console.log("new token url loaded :" + response.data.token);
        setAccessTokenTwo(response.data.token);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let navigate = useNavigate();

  const dashone = () => {
    let path = `/home`;
    navigate(path);
  };
  return (
    <>
      <div className="container">
        <Appbar />
        <div class="row justify-content-md-end">
          <div class="col col-lg-3">
         
            <Button
              variant="contained"
              size="large"
              onClick={dashone}
              startIcon={<KeyboardBackspaceIcon />}
            >
              Back to Dashboards
            </Button>
          </div>
        </div>



        {embbedurl ? (
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual and qna
              id: reportID,
              embedUrl: embbedurl,
              accessToken: accessTokenTwo,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                // background: models.BackgroundType.Transparent,
              },
                filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "Sheet2",
                    column: "Tenant_Id",
                  },
                  operator: "In",
                  values: ["T1"],
                } 
              ],
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    console.log("Report rendered");
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={"Embed-container"}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
              // this.report = embeddedReport as Report;
            }}
          />
        ) : (
          ""
        )}

    
      </div>
    </>
  );
}

export default Index;
